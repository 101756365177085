<template>
<div id="token-read">
    <v-dialog scrollable v-model="dialog" persistent max-width="400" transition="dialog-top-transition">
        <v-card :loading="loading" :disabled="loading">
            <v-toolbar class="elevation-custom-table">
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Token</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <v-text-field readonly v-model="token.name" label="Nombre" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field readonly v-model="token.value" label="Valor" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn class="text-none" color="red" text @click="confirm = true">Eliminar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="text-none" color="grey darken-1" text @click="$router.push('/configuracion')">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="confirm" persistent max-width="300" transition="dialog-top-transition">
        <v-card color>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <span class="font-weight-bold red--text">
                    Advertencia
                </span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="text-center">
                Se eliminara este elemento y no podra recuperarse, ¿deseas continuar?
            </v-card-text>
            <v-card-actions>
                <v-btn text class="text-none" color="grey darken-1" @click="confirm = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" color="red" @click="destroy">Eliminar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    name: 'TokenEdit',
    data: () => ({
        loading: false,
        confirm: false,
        dialog: true,
        token: {
            _id: null,
            name: null,
            value: null,
        },
    }),
    methods: {
        async read() {
            this.loading = true;
            await this.$axios.get('/api/token/' + this.$route.params.id).then(response => {
                if (response.status == 200) {
                    this.token = response.data;
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        },
        async destroy() {
            this.loading = true;
            await this.$axios.delete('/api/token/' + this.$route.params.id).then(response => {
                if(response.status == 204){
                    this.$store.commit('token/DELETE_TOKEN', this.$route.params.id);
                    this.$router.push('/configuracion');
                }
            }).catch(error => {
                console.log(error);
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.read();
    }
}
</script>
